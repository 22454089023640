import React from "react";
import PropTypes from "prop-types";
import TaskIcons from "../../utils/TaskIcons";

const pointsLabel = [
  "bg-white border-green-500 text-green-500",
  "bg-green-500 border-green-500 text-white",
  "bg-golden-gradient shine-effect text-white",
];

export const Image = ({ imageUrl }) => (
  <div className="relative mb-2 mx-auto overflow-hidden">
    <a href={imageUrl} target="_blank" rel="noopener noreferrer">
      <img
        src={imageUrl}
        alt="Task"
        className="rounded cursor-pointer w-full h-full object-fit"
      />
      <svg
        className="absolute top-2 right-2 w-6 h-6 text-gray-600 z-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>
    </a>
  </div>
);

const TaskDisplay = ({ task }) => {
  if (!task) {
    return (
      <div className="bg-white shadow-md rounded-lg p-4 mb-4">
        No task available
      </div>
    );
  }

  const pointsClass =
    pointsLabel[
      Math.min(Math.floor((task.points || 0) / 150), pointsLabel.length - 1)
    ];

  return (
    <div className="mb-4 relative">
      <h2 className="text-xl font-bold mb-2">
        {TaskIcons[task.type]} {task.text || "No task text"}
      </h2>
      {task.imageUrl && <Image imageUrl={task.imageUrl} />}
      {task.type === "single_choice" && (
        <ul className="list-disc list-inside">
          {task.choices &&
            task.choices.map((choice, index) => (
              <li key={index} className="mb-1">
                {choice.text}
                {choice.imageUrl && (
                  <img
                    src={choice.imageUrl}
                    alt={`Choice ${index + 1}`}
                    className="mt-1 rounded max-h-[400px] max-w-[800px] mx-auto"
                  />
                )}
              </li>
            ))}
        </ul>
      )}
      {task.points > 0 && (
        <div
          className={`absolute -top-10 -right-10 border-2 rounded-full flex flex-col items-center justify-center w-14 h-14 ${pointsClass}`}
        >
          <span className="font-bold text-lg leading-none bottom-5 absolute">
            {task.points}
          </span>
          <span className="text-xs absolute bottom-2">max</span>
        </div>
      )}
    </div>
  );
};

TaskDisplay.propTypes = {
  task: PropTypes.shape({
    text: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    type: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.string),
    answerImageUrl: PropTypes.string,
  }).isRequired,
};

export default TaskDisplay;
