import React, { lazy, Suspense } from "react";
import { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  Link,
  Outlet,
} from "react-router-dom";
import ProjectorView from "./components/projector/ProjectorView";
import { AuthProvider } from "./contexts/AuthContext";
import { GameStateProvider } from "./contexts/GameStateContext";
import { useAuth } from "./hooks/useAuth";

const Login = lazy(() => import("./pages/Login"));
const LoginSent = lazy(() => import("./pages/LoginSent"));
const Player = lazy(() => import("./pages/Player"));
const GameMaster = lazy(() => import("./pages/GameMaster"));
const Admin = lazy(() => import("./pages/Admin"));

const routes = {
  "/login": { title: "Login", label: "Login", showInNav: false },
  "/login-sent": { title: "Login Sent", label: "Login Sent", showInNav: false },
  "/player": { title: "Player View", label: "Player", showInNav: true },
  "/gamemaster": {
    title: "Game Master",
    label: "Game Master",
    showInNav: true,
  },
  "/projector": {
    title: "Projector View",
    label: "Projector",
    showInNav: true,
  },
  "/admin": { title: "Admin Panel", label: "Admin", showInNav: true },
};
export const useDocumentTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const route = routes[location.pathname] || routes["/player"];
    document.title = `${route.title} | Seunghee and Slava Welcome Dinner`;
  }, [location]);
};

const Navigation = () => {
  const { user } = useAuth();
  const navLinks = Object.entries(routes)
    .filter(([_, routeInfo]) => routeInfo.showInNav)
    .map(([path, routeInfo]) => ({ to: path, label: routeInfo.label }));

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 bg-gray-100 p-2 text-xs z-[10001]">
        <ul className="flex justify-center space-x-4">
          {navLinks.map((link) => (
            <li key={link.to}>
              <Link to={link.to} className="text-blue-600 hover:text-blue-800">
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

const Layout = () => {
  useDocumentTitle();
  return (
    <>
      <div className="min-h-screen pb-[400px] max-w-[1200px] mx-auto">
        <Outlet />
      </div>
    </>
  );
};

const NoLayout = () => {
  useDocumentTitle();
  return (
    <>
      <div className="min-h-screen min-w-screen">
        <Outlet />
      </div>
    </>
  );
};

const AdminLayout = () => {
  useDocumentTitle();
  return (
    <>
      <div className="min-h-screen min-w-screen mx-auto">
        <Outlet />
      </div>
      <Navigation />
    </>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <GameStateProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/admin/*" element={<AdminLayout />}>
                <Route path="*" element={<Admin />} />
              </Route>
              <Route path="/gamemaster" element={<AdminLayout />}>
                <Route index element={<GameMaster />} />
              </Route>

              <Route path="/projector" element={<NoLayout />}>
                <Route index element={<ProjectorView />} />
              </Route>

              <Route path="/" element={<Layout />}>
                <Route index element={<Player />} />
                <Route path="login" element={<Login />} />
                <Route path="login-sent" element={<LoginSent />} />
                <Route path="player" element={<Player />} />
              </Route>
            </Routes>
          </Suspense>
        </GameStateProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
export { routes };
