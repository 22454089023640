import React, { createContext, useState, useEffect } from "react";
import { auth } from "../firebase/config";
import {
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithEmailAndPassword,
} from "firebase/auth";

// always start with at least 1 admin
const adminEmail = "imslavko@gmail.com";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser && authUser.reloadUserInfo) {
        const userData = JSON.parse(authUser.reloadUserInfo.customAttributes);
        setUser({
          ...authUser,
          ...userData,
          isAdmin: userData?.isAdmin || authUser.email === adminEmail || false,
          id: authUser.uid,
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const sendLoginLink = (email, actionCodeSettings) => {
    return sendSignInLinkToEmail(auth, email, actionCodeSettings);
  };

  const completeSignIn = (email, href) => {
    if (isSignInWithEmailLink(auth, href)) {
      return signInWithEmailLink(auth, email, href);
    }
    return Promise.reject(new Error("Invalid sign-in link"));
  };

  const signIn = (email, password) =>
    signInWithEmailAndPassword(auth, email, password);

  const signOut = () => auth.signOut();

  const value = {
    user,
    loading,
    sendLoginLink,
    completeSignIn,
    signIn,
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
