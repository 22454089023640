export const calcPoints = (task, answer, teamId) => {
  const correctAnswer = task.rightAnswer;
  switch (task.type) {
    case "single_choice":
    case "user_choice":
    case "write_in":
      return answer === correctAnswer ? Number(task.points || 0) : 0;
    case "numeric":
      return calculateNumericScore(task, answer);
    case "task_write_in_choice":
      return calculateWhiplashScore(task, answer, teamId);
    default:
      return 0;
  }
};

// Penalizes exponentially the farther the answer is from correct answer
function calculateNumericScore(task, answer) {
  const correctAnswer = Number(task.rightAnswer);
  const difference = Math.abs(answer - correctAnswer);
  const fullPoints = Number(task.points);

  const diffLn = Math.log(difference + 1);
  const maxLn = Math.log(
    Math.min(
      (Number(task.maxValue || 100) || 100) - correctAnswer,
      correctAnswer,
    ),
  );

  const coeff = Math.min(Math.max(1.0 - diffLn / maxLn, 0.0), 1.0);

  return Math.floor(fullPoints * coeff * 100) / 100.0;
}

function calculateWhiplashScore(task, answer, teamId) {
  let reward = 0;
  if (task.rightAnswer === answer) {
    reward += Number(task.points) / 10;
  }
  if (task.rightAnswerTeamId === teamId) {
    reward += (Number(task.points) / 10) * 9;
  }
  return reward;
}
