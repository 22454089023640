import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useFirestore } from "../../../hooks/useFirestore";
import { useMemo } from "react";
import AnswerToken from "../AnswerToken";

ChartJS.register(ArcElement, Tooltip, Legend);

const COLORS = [
  "#FFB3C6",
  "#A6D2FF",
  "#FFE6A6",
  "#A6E6E6",
  "#D1B3FF",
  "#FFCFA6",
  "#FFB3C6",
  "#A6D2FF",
  "#FFE6A6",
  "#A6E6E6",
];

const RED = "#FF9999";
const GREEN = "#99FF99";
const GRAYSCALE = [
  "#E0E0E0",
  "#C0C0C0",
  "#A0A0A0",
  "#808080",
  "#606060",
  "#404040",
  "#202020",
];

const PieChart = ({ counts, red, green }) => {
  const labels = Object.keys(counts);
  const data = Object.values(counts);

  const colors = [];
  for (let i = 0; i < labels.length; i++) {
    const label = labels[i];
    if (red || green) {
      if (label === red) {
        colors.push(RED);
      } else if (label === green) {
        colors.push(GREEN);
      } else {
        colors.push(GRAYSCALE[i % GRAYSCALE.length]);
      }
    } else {
      colors.push(COLORS[i % COLORS.length]);
    }
  }

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        align: "start",
        display: true,
        onClick: null,
      },
    },
  };

  return (
    <div
      style={{
        margin: "0 auto",
      }}
      className="chartjs-pie"
    >
      <Pie data={chartData} options={options} />
    </div>
  );
};

const PieChartWithData = ({
  taskId,
  teamAnswer,
  rightAnswer,
  renderLabel,
  renderAnswer,
}) => {
  const {
    documents: teamTaskAnswers,
    loading,
    error,
  } = useFirestore("teamTaskAnswers", { taskId });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const answerCounts = teamTaskAnswers.reduce((acc, answer) => {
    acc[answer.answer] = (acc[answer.answer] || 0) + 1;
    return acc;
  }, {});

  const sortedCounts = Object.entries(answerCounts).sort((a, b) => b[1] - a[1]);
  const mappedCounts = sortedCounts.reduce((obj, [key, value]) => {
    obj[renderLabel(key)] = value;
    return obj;
  }, {});

  rightAnswer = renderLabel(rightAnswer);
  teamAnswer = renderLabel(teamAnswer);

  let greenProp = null;
  let redProp = null;

  if (rightAnswer && teamAnswer) {
    if (rightAnswer === teamAnswer) {
      greenProp = rightAnswer;
    } else {
      greenProp = rightAnswer;
      redProp = teamAnswer;
    }
  } else if (rightAnswer || teamAnswer) {
    greenProp = rightAnswer || teamAnswer;
  }

  const topOne = sortedCounts[0];
  const topMany = sortedCounts.filter((x) => x[1] === topOne[1]);
  return (
    <>
      <div className="mb-4">
        <p className="text-sm text-gray-500">
          👑 Top {topMany.length === 1 ? "pick" : "picks"} 👑:{" "}
        </p>
        {topMany.map(([label, count], i) => (
          <div key={i}>
            <span className="flex flex-row items-center">
              {renderAnswer(label)}
              <div className="ml-2">
                ({count} {count === 1 ? "vote" : "votes"})
              </div>
            </span>
          </div>
        ))}
      </div>
      <PieChart counts={mappedCounts} green={greenProp} red={redProp} />
    </>
  );
};

const SingleChoicePieChart = ({ taskId, teamAnswer, rightAnswer }) => {
  const { documents: choices } = useFirestore("taskChoices", { taskId });

  const choiceMap = useMemo(() => {
    return choices.reduce((acc, choice) => {
      acc[choice.id] = choice.text;
      return acc;
    }, {});
  }, [choices]);

  const renderLabel = (answerId) => choiceMap[answerId] || answerId;
  const renderAnswer = (answerId) => (
    <AnswerToken answer={answerId} taskType="single_choice" />
  );

  return (
    <PieChartWithData
      taskId={taskId}
      teamAnswer={teamAnswer}
      rightAnswer={rightAnswer}
      renderLabel={renderLabel}
      renderAnswer={renderAnswer}
    />
  );
};

const UserChoicePieChart = ({ taskId, teamAnswer, rightAnswer }) => {
  const { documents: users } = useFirestore("userProfiles");

  const userMap = useMemo(() => {
    return users.reduce((acc, user) => {
      acc[user.id] = user.displayName;
      return acc;
    }, {});
  }, [users]);

  const renderLabel = (userId) => userMap[userId] || userId;
  const renderAnswer = (answerId) => (
    <AnswerToken answer={answerId} taskType="user_choice" />
  );

  return (
    <PieChartWithData
      taskId={taskId}
      teamAnswer={teamAnswer}
      rightAnswer={rightAnswer}
      renderLabel={renderLabel}
      renderAnswer={renderAnswer}
    />
  );
};

const TaskWriteInChoicePieChart = ({
  taskId,
  sourceTaskId,
  teamAnswer,
  rightAnswer,
}) => {
  const { documents: teamAnswers } = useFirestore("teamTaskAnswers", {
    taskId: sourceTaskId,
  });

  const answerMap = useMemo(() => {
    return teamAnswers.reduce((acc, answer) => {
      acc[answer.id] = answer.answer;
      return acc;
    }, {});
  }, [teamAnswers]);

  const renderLabel = (answerId) => answerMap[answerId] || answerId;
  const renderAnswer = (answerId) => (
    <AnswerToken answer={answerId} taskType="task_write_in_choice" />
  );

  return (
    <PieChartWithData
      taskId={taskId}
      teamAnswer={teamAnswer}
      rightAnswer={rightAnswer}
      renderLabel={renderLabel}
      renderAnswer={renderAnswer}
    />
  );
};

const DefaultPieChart = ({ taskId, teamAnswer, rightAnswer }) => {
  return (
    <PieChartWithData
      taskId={taskId}
      teamAnswer={teamAnswer}
      rightAnswer={rightAnswer}
      renderLabel={(answer) => answer}
      renderAnswer={(answer) => answer}
    />
  );
};

const TaskTypePieChart = ({ task, ...props }) => {
  switch (task.type) {
    case "single_choice":
      return <SingleChoicePieChart {...props} />;
    case "user_choice":
      return <UserChoicePieChart {...props} />;
    case "task_write_in_choice":
      return (
        <TaskWriteInChoicePieChart
          sourceTaskId={task.sourceTaskId}
          {...props}
        />
      );
    default:
      return <DefaultPieChart {...props} />;
  }
};

export default TaskTypePieChart;
