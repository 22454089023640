import React, { useState, useEffect, useRef } from "react";
import { useFirestore } from "../../hooks/useFirestore";

const UserSelect = ({ onSelect, disabled, initialValue }) => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const { documents: userProfiles } = useFirestore("userProfiles");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialValue && userProfiles.length > 0) {
      const initialUser = userProfiles.find((user) => user.id === initialValue);
      if (initialUser) {
        setSelectedUser(initialUser);
        setInputValue(initialUser.displayName);
      }
    }
  }, [initialValue, userProfiles]);

  useEffect(() => {
    if (inputValue && !selectedUser) {
      const filtered = userProfiles.filter(
        (user) =>
          user.displayName.toLowerCase().includes(inputValue.toLowerCase()) ||
          user.email.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setSuggestions(filtered);
      setHighlightedIndex(-1);
    } else {
      setSuggestions([]);
    }
  }, [inputValue, userProfiles, selectedUser]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setSelectedUser(null);
  };

  const handleSelect = (user) => {
    setSelectedUser(user);
    setInputValue(user.displayName);
    setSuggestions([]);
    onSelect(user);
  };

  const handleClear = () => {
    setSelectedUser(null);
    setInputValue("");
    onSelect(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex,
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === "Enter" && highlightedIndex !== -1) {
      e.preventDefault();
      handleSelect(suggestions[highlightedIndex]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSuggestions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex items-center border rounded-md p-2">
        {selectedUser ? (
          <div className="flex items-center bg-blue-100 rounded-full px-3 py-1">
            <span>{selectedUser.displayName}</span>
            <button
              onClick={handleClear}
              className="ml-2 text-gray-500 hover:text-gray-700"
              disabled={disabled}
            >
              ×
            </button>
          </div>
        ) : (
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="w-full outline-none"
            placeholder="Search for a user..."
            disabled={disabled}
            ref={inputRef}
          />
        )}
      </div>
      {suggestions.length > 0 && (
        <ul
          ref={dropdownRef}
          className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto"
        >
          {suggestions.map((user, index) => (
            <li
              key={user.id}
              onClick={() => handleSelect(user)}
              className={`px-4 py-2 cursor-pointer ${
                index === highlightedIndex ? "bg-gray-100" : "hover:bg-gray-100"
              }`}
            >
              <div>{user.displayName}</div>
              <div className="text-sm text-gray-500">{user.email}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const UserPill = ({ userId }) => {
  const { documents: userProfiles } = useFirestore("userProfiles");
  const user = userProfiles.find((user) => user.id === userId);
  if (!user) return null;
  return (
    <div className="bg-blue-100 rounded-full px-3 py-1 mt-1 w-max">
      {user.displayName}
    </div>
  );
};

export default UserSelect;
