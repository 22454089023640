import React, { createContext, useState, useEffect, useMemo } from "react";
import { db } from "../firebase/config";
import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useFirestore } from "../hooks/useFirestore";

export const GameStateContext = createContext();

export const useGameState = () => React.useContext(GameStateContext);

export const GameStateProvider = ({ children }) => {
  const defaultGameState = useMemo(
    () => ({
      currentState: "init", // "init", "getReady", "task", "results"
      timerExpiration: new Date(),
      currentGroupId: null,
      currentGroup: null,
      nextGroup: null,
    }),
    [],
  );

  const [gameState, setGameState] = useState(defaultGameState);
  const { documents: unsortedGroups } = useFirestore("taskGroups");
  const groups = useMemo(() => {
    return [...unsortedGroups].sort((a, b) => a.position - b.position);
  }, [unsortedGroups]);

  useEffect(() => {
    const gameStateRef = doc(db, "gameState", "current");

    const unsubscribe = onSnapshot(gameStateRef, (doc) => {
      if (doc.exists()) {
        setGameState((prevState) => ({ ...prevState, ...doc.data() }));
      } else {
        // If the document doesn't exist, create it with the default state
        setDoc(gameStateRef, defaultGameState)
          .then(() => setGameState(defaultGameState))
          .catch((error) =>
            console.error("Error creating game state document:", error),
          );
      }
    });

    return unsubscribe;
  }, [defaultGameState]);

  const updateGameState = async (newState) => {
    try {
      const gameStateRef = doc(db, "gameState", "current");
      await setDoc(
        gameStateRef,
        { ...gameState, ...newState },
        { merge: true },
      );
    } catch (error) {
      console.error("Error updating game state:", error);
    }
  };

  const advanceGameState = async () => {
    const currentIndex = groups.findIndex(
      (group) => group.id === gameState.currentGroupId,
    );
    const nextGroup = groups[currentIndex + 1] || null;

    switch (gameState.currentState) {
      case "init":
        if (groups.length > 0) {
          await updateGameState({
            currentState: "getReady",
            currentGroupId: groups[0].id,
            currentGroup: groups[0],
            nextGroup: groups[1] || null,
          });
        }
        break;
      case "getReady":
        await updateGameState({
          currentState: "task",
          timerExpiration: Date.now() + 300000, // Set 5 minutes from now
        });
        break;
      case "task":
        await Promise.all([
          updateGameState({
            currentState: "results",
            timerExpiration: null,
          }),
          resetTeamDone(),
        ]);
        break;
      case "results":
        if (nextGroup) {
          await updateGameState({
            currentState: "getReady",
            currentGroupId: nextGroup.id,
            currentGroup: nextGroup,
            nextGroup: groups[currentIndex + 2] || null,
          });
        } else {
          await updateGameState({
            currentState: "gameOver",
            currentGroupId: null,
            currentGroup: null,
            nextGroup: null,
          });
        }
        break;
      default:
        break;
    }
  };
  const goBackGameState = async () => {
    switch (gameState.currentState) {
      case "task":
        await updateGameState({
          currentState: "getReady",
          timerRunning: false,
        });
        break;
      case "results":
        await updateGameState({
          currentState: "task",
          timerRunning: false,
        });
        break;
      case "getReady":
        const currentIndex = groups.findIndex(
          (task) => task.id === gameState.currentGroupId,
        );
        if (currentIndex > 0) {
          const previousTask = groups[currentIndex - 1];
          await updateGameState({
            currentState: "results",
            currentGroupId: previousTask.id,
            currentGroup: previousTask,
            nextGroup: groups[currentIndex],
          });
        }
        break;
      case "gameOver":
        const lastTask = groups[groups.length - 1];
        if (lastTask) {
          await updateGameState({
            currentState: "results",
            currentGroupId: lastTask.id,
            currentGroup: lastTask,
            nextGroup: null,
          });
        }
        break;
      default:
        break;
    }
  };

  const resetGameState = async () => {
    try {
      const gameStateRef = doc(db, "gameState", "current");
      const resetState = {
        ...defaultGameState,
        currentGroupId: groups.length > 0 ? groups[0].id : null,
        currentGroup: groups.length > 0 ? groups[0] : null,
        nextGroup: groups.length > 1 ? groups[1] : null,
      };
      await setDoc(gameStateRef, resetState);
      setGameState(resetState);

      await resetTeamDone();
    } catch (error) {
      console.error("Error resetting game state:", error);
    }
  };

  const canAdvance = useMemo(() => {
    return gameState.currentState !== "gameOver";
  }, [gameState.currentState]);

  const canBack = useMemo(() => {
    return (
      gameState.currentState !== "init" &&
      (gameState.currentState !== "getReady" ||
        groups.findIndex((task) => task.id === gameState.currentGroupId) > 0)
    );
  }, [gameState.currentState, gameState.currentGroupId, groups]);

  const canSkip = useMemo(() => {
    return (
      gameState.currentState === "getReady" &&
      groups.findIndex((task) => task.id === gameState.currentGroupId) <
        groups.length - 1
    );
  }, [gameState.currentState, gameState.currentGroupId, groups]);

  const setTeamDone = async (teamId) => {
    await setDoc(
      doc(db, "teamDone", "current"),
      {
        [teamId]: true,
      },
      { merge: true },
    );
  };

  const resetTeamDone = async () => {
    await setDoc(doc(db, "teamDone", "current"), {});
  };

  const value = {
    gameState,
    updateGameState,
    advanceGameState,
    goBackGameState,
    resetGameState,
    groups,
    canAdvance,
    canBack,
    canSkip,
    setTeamDone,
  };

  return (
    <GameStateContext.Provider value={value}>
      {children}
    </GameStateContext.Provider>
  );
};
