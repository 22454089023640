import React from "react";
import { useTeamScores } from "../common/Scoring";
const toFixed = (x) => {
  if (typeof x !== "number") {
    return x;
  }
  if (x === Math.floor(x)) {
    return x.toString();
  }
  return x.toFixed(2);
};

const TeamStandings = () => {
  const teams = useTeamScores();
  const topScores = [...new Set(teams.map((team) => team.score))];
  return (
    <div className="team-standings p-2">
      <h2 className="text-2xl font-semibold mb-2">Team Standings</h2>
      <table className="w-full">
        <tbody>
          {teams.map((team, index) => (
            <tr key={team.id} className="mb-2">
              {topScores[0] > 0 && (
                <td className="w-8">
                  <span className="text-2xl">
                    {team.score === 0
                      ? ""
                      : topScores.indexOf(team.score) === 0
                        ? "🥇"
                        : topScores.indexOf(team.score) === 1
                          ? "🥈"
                          : topScores.indexOf(team.score) === 2
                            ? "🥉"
                            : ""}
                  </span>
                </td>
              )}
              <td>
                <div className="text-lg">{team.name}</div>
                <div className="text-sm font-light text-gray-500">
                  {team.members.join(", ")}
                </div>
              </td>
              <td className="text-right">{toFixed(team.score)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamStandings;
