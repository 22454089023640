import { useFirestore } from "../../../hooks/useFirestore";

export const WordCloud = ({ inputs }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
      {inputs.map((word, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "#e6f2ff",
            borderRadius: "16px",
            padding: "4px 12px",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          {word}
        </div>
      ))}
    </div>
  );
};

const WordCloudWithData = ({ taskId }) => {
  const { documents: teamTaskAnswers } = useFirestore("teamTaskAnswers", {
    taskId,
  });
  const inputs = teamTaskAnswers.map(({ answer }) => answer);
  return <WordCloud inputs={inputs} />;
};

export default WordCloudWithData;
