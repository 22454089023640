import React from "react";
import ChoicePill from "./ChoicePill";
import TeamAnswerPill from "./TeamAnswerPill";
import { UserPill } from "./UserSelect";

const AnswerToken = ({ taskType, answer }) => {
  if (taskType === "user_choice") {
    return <UserPill userId={answer} />;
  }
  if (taskType === "single_choice") {
    return <ChoicePill choiceId={answer} />;
  }
  if (taskType === "task_write_in_choice") {
    return <TeamAnswerPill teamAnswerId={answer} />;
  }
  return <div>{answer}</div>;
};

export default AnswerToken;
