import React from "react";
import { useFirestore } from "../../hooks/useFirestore";

const ChoicePill = ({ choiceId }) => {
  const { documents: choices } = useFirestore("taskChoices", {
    id: choiceId,
  });

  if (!choiceId || !choices.length) return null;
  const choice = choices[0];

  return (
    <div className="bg-blue-100 rounded-full px-3 py-1 mt-1 w-max">
      {choice.text}
    </div>
  );
};

export default ChoicePill;
