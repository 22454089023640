import React, { useState, useEffect } from "react";
import { useGameState } from "../../contexts/GameStateContext";

const Timer = ({ isFloating = true }) => {
  const { gameState } = useGameState();
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const updateTimer = () => {
      if (gameState?.timerExpiration) {
        const now = Date.now();
        const remaining = Math.max(
          0,
          Math.floor((gameState.timerExpiration - now) / 1000),
        );
        setTimeLeft(remaining);
      } else {
        setTimeLeft(0);
      }
    };

    updateTimer();
    const timer = setInterval(updateTimer, 1000);

    return () => clearInterval(timer);
  }, [gameState?.timerExpiration]);

  const getTimerColor = () => {
    if (timeLeft <= 60) return "bg-red-500";
    if (timeLeft <= 180) return "bg-yellow-500";
    return "bg-green-500";
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const animationClass =
    timeLeft <= 15 && timeLeft > 0
      ? "animate-[shake_0.5s_ease-in-out_infinite]"
      : "";

  const floatingClasses = isFloating
    ? "fixed bottom-10 right-0 z-[30000] rounded-l-lg border border-r-0 w-[130px]"
    : "rounded-lg border w-[140px]";

  return (
    <div
      className={`text-4xl font-bold text-white p-3 text-center font-mono ${getTimerColor()} ${floatingClasses} ${animationClass}`}
    >
      {formatTime(timeLeft)}
    </div>
  );
};

export default Timer;
