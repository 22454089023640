import React from "react";
import { useGameState } from "../../contexts/GameStateContext";
import { useFirestore } from "../../hooks/useFirestore";
import TaskDisplay from "../common/TaskDisplay";
import Timer from "../common/Timer";
import ResultsDisplay from "../player/ResultsDisplay";
import TeamStandings from "./TeamStandings";

const Results = ({ group }) => {
  const { documents: tasks } = useFirestore(
    "tasks",
    {
      groupId: group && group.id,
    },
    {
      position: 1,
    },
  );

  return (
    <div className="p-16 h-screen overflow-y-auto overflow-x-hidden">
      {tasks.map((task) => (
        <div key={task.id} className="mb-[400px]">
          <TaskDisplay task={task} />
          <ResultsDisplay task={task} />
        </div>
      ))}
    </div>
  );
};

const InProgress = ({ title, getReady }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <div
        style={{
          transform: "scale(3)",
          transformOrigin: "center",
          width: "fit-content",
          height: "fit-content",
          margin: "auto",
        }}
      >
        <div className="flex flex-col items-center pb-8">
          <h1 className="font-bold text-gray-700 mb-4 text-3xl">{title}</h1>
          {getReady ? (
            <div className="text-green-500 font-bold">Get Ready</div>
          ) : (
            <Timer isFloating={false} />
          )}
        </div>
      </div>
    </div>
  );
};

const Projection = () => {
  const { gameState } = useGameState();
  switch (gameState.currentState) {
    case "task":
    case "getReady":
      return (
        <InProgress
          title={gameState.currentGroup.text}
          getReady={gameState.currentState === "getReady"}
        />
      );
    case "results":
      return <Results group={gameState.currentGroup} />;
    default:
      return null;
  }
};

const ProjectorView = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="projector-view relative">
      <button
        className="absolute top-2 right-0 bg-gray-200 text-gray-700 p-2 rounded"
        onClick={toggleCollapse}
      >
        {isCollapsed ? "◀" : "▶"}
      </button>
      <div
        className={`grid ${isCollapsed ? "grid-cols-1" : "grid-cols-4"} gap-4`}
      >
        <div className={`${isCollapsed ? "col-span-1" : "col-span-3"}`}>
          <Projection />
        </div>
        {!isCollapsed && (
          <div className="col-span-1 h-screen overflow-y-auto">
            <TeamStandings />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectorView;
