import React from "react";
import { PointsAward } from "../common/Scoring";
import AnswerToken from "../common/AnswerToken";
import { useMemo } from "react";
import NumericHistogramWithData from "../common/visualizations/NumericHistogram";
import PieChartWithData from "../common/visualizations/SingleChoicePieChart";
import WordCloudWithData from "../common/visualizations/WordCloud";
import { Image } from "../common/TaskDisplay";

const ResultsDisplay = ({ task, teamAnswer, teamId }) => {
  const visualization = useMemo(() => {
    switch (task.type) {
      case "single_choice":
      case "user_choice":
      case "task_write_in_choice":
        return (
          <PieChartWithData
            taskId={task.id}
            task={task}
            teamAnswer={teamAnswer}
            rightAnswer={task.rightAnswer}
          />
        );
      case "numeric":
        return (
          <>
            <NumericHistogramWithData
              taskId={task.id}
              rightAnswer={task.rightAnswer}
              teamAnswer={teamAnswer}
            />
            {task.personalAnswerPoints && (
              <NumericHistogramWithData taskId={task.id} usePersonalAnswers />
            )}
          </>
        );
      case "write_in":
        return <WordCloudWithData taskId={task.id} />;
      default:
        return null;
    }
  }, [task, teamAnswer]);
  const viz = visualization ? (
    <div className="mt-4">{visualization}</div>
  ) : null;

  return (
    <div className="mt-4 p-4 bg-gray-100">
      <h3 className="text-xl font-bold mb-2">Results</h3>
      {task.answerImageUrl && <Image imageUrl={task.answerImageUrl} />}
      {task.rightAnswer !== undefined && task.rightAnswer !== "" && (
        <>
          <div>
            <p className="text-sm text-gray-500">Right answer: </p>
            <AnswerToken taskType={task.type} answer={task.rightAnswer} />
          </div>
          {teamId && (
            <div className="mt-2">
              <p className="text-sm text-gray-500">Points received: </p>
              <PointsAward
                task={task}
                teamAnswer={teamAnswer}
                teamId={teamId}
              />
            </div>
          )}
        </>
      )}
      {viz}
    </div>
  );
};

export default ResultsDisplay;
