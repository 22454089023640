import { storage } from "../firebase/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { useCallback } from "react";

import { useEffect, useState } from "react";
import { db } from "../firebase/config";
import {
  collection,
  query,
  onSnapshot,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  where,
  orderBy,
  deleteField,
  getDocs,
  writeBatch,
} from "firebase/firestore";

const formatData = (x) => {
  const r = {};
  for (const field in x) {
    r[field] = x[field] === undefined ? deleteField() : x[field];
  }
  return r;
};

const translateFilters = (filterConditions) => {
  const filters = [];
  if (filterConditions) {
    filters.push(
      ...Object.entries(filterConditions).map(([field, value]) =>
        where(
          field === "id" ? "__name__" : field,
          "==",
          // hack: allow queries that basically select nothing
          value || "special undefined value",
        ),
      ),
    );
  }
  return filters;
};

export const useFirestore = (
  collectionName,
  filterConditions = null,
  sortConditions = null,
) => {
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      if (!collectionName) {
        setError("Collection name is required");
        setLoading(false);
        return;
      }

      const collectionRef = collection(db, collectionName);
      const filters = translateFilters(filterConditions);

      const sortOrders = [];
      if (sortConditions) {
        sortOrders.push(
          ...Object.entries(sortConditions).map(([field, order]) =>
            order === 1 ? orderBy(field, "asc") : orderBy(field, "desc"),
          ),
        );
      }

      const q = query(collectionRef, ...filters, ...sortOrders);

      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setDocuments(docs);
          setError(null);
          setLoading(false);
        },
        (err) => {
          console.error(
            "Error fetching documents:",
            collectionName,
            JSON.stringify(filterConditions),
            err,
          );
          setError("Failed to fetch documents");
          setLoading(false);
        },
      );

      return () => unsubscribe();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      collectionName,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(filterConditions),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(sortConditions),
    ],
  );

  const addDocument = useCallback(
    async (data) => {
      if (!collectionName) {
        throw new Error("Collection name is required");
      }
      try {
        setLoading(true);
        await addDoc(collection(db, collectionName), data);
      } catch (err) {
        console.error("Error adding document:", err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [collectionName],
  );
  const updateDocument = useCallback(
    async (id, data) => {
      if (!collectionName || !id) {
        throw new Error("Collection name and document ID are required");
      }
      try {
        setLoading(true);
        await updateDoc(doc(db, collectionName, id), formatData(data));
      } catch (err) {
        console.error("Error updating document:", err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [collectionName],
  );

  const deleteDocument = useCallback(
    async (id) => {
      if (!collectionName || !id) {
        throw new Error("Collection name and document ID are required");
      }
      try {
        setLoading(true);
        await deleteDoc(doc(db, collectionName, id));
      } catch (err) {
        console.error("Error deleting document:", err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [collectionName],
  );

  const uploadImage = useCallback(async (file) => {
    if (!file) return null;

    setLoading(true);
    try {
      const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } finally {
      setLoading(false);
    }
  }, []);

  const addDocumentWithImage = useCallback(
    async (data, imageFile) => {
      if (!collectionName) {
        throw new Error("Collection name is required");
      }
      try {
        setLoading(true);
        let imageUrl = null;
        if (imageFile) {
          imageUrl = await uploadImage(imageFile);
        }
        const docData = { ...data, imageUrl };
        await addDoc(collection(db, collectionName), docData);
      } catch (err) {
        console.error("Error adding document with image:", err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [collectionName, uploadImage],
  );

  const updateDocumentWithImage = useCallback(
    async (id, data, imageFile) => {
      if (!collectionName || !id) {
        throw new Error("Collection name and document ID are required");
      }
      try {
        setLoading(true);
        let imageUrl = data.imageUrl;
        if (imageFile) {
          imageUrl = await uploadImage(imageFile);
        }
        const docData = { ...data, imageUrl };
        await updateDoc(doc(db, collectionName, id), formatData(docData));
      } catch (err) {
        console.error("Error updating document with image:", err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [collectionName, uploadImage],
  );

  return {
    documents,
    error,
    loading,
    addDocument,
    updateDocument,
    deleteDocument,
    addDocumentWithImage,
    updateDocumentWithImage,
    uploadImage,
  };
};

export const fetchAll = async (collectionName, filters) => {
  const snapshot = await getDocs(
    query(collection(db, collectionName), ...translateFilters(filters)),
  );
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const updateBulkDocs = async (collectionName, docsToUpdate) => {
  const batch = writeBatch(db);
  docsToUpdate.forEach(({ id, docObject }) => {
    const docRef = doc(db, collectionName, id);
    batch.update(docRef, formatData(docObject));
  });
  await batch.commit();
};
