import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

import { useFirestore } from "../../../hooks/useFirestore";
import AnswerToken from "../AnswerToken";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
);

function toFixed(value, fixed = 2) {
  if (value === Math.floor(value)) {
    return value.toFixed(0);
  }
  return value.toFixed(fixed);
}

const NumericHistogram = ({ points, markers }) => {
  let maxValue = Math.max(
    ...points,
    ...Object.values(markers).map((m) => m.value),
  );
  const minValue = Math.min(
    0,
    ...points,
    ...Object.values(markers).map((m) => m.value),
  );
  let range = maxValue - minValue;

  maxValue += Math.ceil(range * 0.1);
  range = maxValue - minValue;

  if (points.length === 0) {
    range = 10;
  }

  const maxBuckets = 20;
  const minBuckets = 5;
  const targetPointsPerBucket = 10;

  // Calculate initial number of buckets
  let buckets = Math.min(
    Math.max(Math.round(points.length / targetPointsPerBucket), minBuckets),
    maxBuckets,
  );

  // Calculate bucket size and round up to nearest integer
  let bucketSize = Math.floor(range / buckets + 1);

  // Recalculate number of buckets based on integer bucket size
  buckets = Math.min(
    Math.max(Math.ceil(range / bucketSize), minBuckets),
    maxBuckets,
  );

  // Final adjustment to ensure bucket size is an integer
  bucketSize = Math.ceil(range / buckets);

  const histogramData = Array(buckets).fill(0);
  points.forEach((point) => {
    const bucketIndex = Math.min(
      Math.floor((point - minValue) / bucketSize),
      buckets - 1,
    );
    histogramData[bucketIndex]++;
  });

  const median = points.sort((a, b) => a - b)[Math.floor(points.length / 2)];
  const average = points.reduce((sum, point) => sum + point, 0) / points.length;
  const closest = points.reduce((prev, curr) => {
    return Math.abs(curr - average) < Math.abs(prev - average) ? curr : prev;
  });

  markers = {
    ...markers,
    med: { value: median, color: "#999", position: "start", offset: -0.1 },
    avg: { value: average, color: "#999", position: "start", offset: -0.1 },
  };

  const data = {
    labels: Array.from(
      { length: buckets },
      (_, i) =>
        `${toFixed(minValue + i * bucketSize)}-${toFixed(minValue + (i + 1) * bucketSize - 1)}`,
    ),
    datasets: [
      {
        label: "Count",
        data: histogramData,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const annotations = {};
  Object.entries(markers).forEach(([label, marker]) => {
    let value =
      Math.floor((marker.value + minValue) / bucketSize) + (marker.offset || 0);
    annotations[label] = {
      type: "line",
      mode: "vertical",
      xMin: value,
      xMax: value,
      borderColor: marker.color || "tomato",
      borderWidth: 2,
      label: {
        content: label,
        display: true,
        position: marker.position || "center",
        backgroundColor: marker.color,
        z: 100,
      },
    };
  });

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Value Ranges",
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            const item = tooltipItems[0];
            return `Range: ${item && item.label}`;
          },
        },
      },
      annotation: {
        annotations,
      },
    },
  };

  return (
    <>
      <div className="mb-4">
        <p className="text-sm text-gray-500">Average answer: </p>
        <AnswerToken taskType="numeric" answer={average} />
      </div>
      <div className="mb-4">
        <p className="text-sm text-gray-500">Median answer: </p>
        <AnswerToken taskType="numeric" answer={median} />
      </div>
      <div className="mb-4">
        <p className="text-sm text-gray-500">👑 Closest guess 👑:</p>
        <AnswerToken taskType="numeric" answer={closest} />
      </div>
      <Bar data={data} options={options} />
    </>
  );
};

const NumericHistogramWithData = ({
  taskId,
  rightAnswer,
  teamAnswer,
  usePersonalAnswers,
}) => {
  if (usePersonalAnswers) {
    return <NumericHistorgramPersonal taskId={taskId} />;
  } else {
    return (
      <NumericHistorgramTeam
        taskId={taskId}
        rightAnswer={rightAnswer}
        teamAnswer={teamAnswer}
      />
    );
  }
};

const NumericHistorgramTeam = ({ taskId, rightAnswer, teamAnswer }) => {
  const {
    documents: answers,
    loading,
    error,
  } = useFirestore("teamTaskAnswers", { taskId });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <NumericHistogramWithDataCommon
      rightAnswer={rightAnswer}
      teamAnswer={teamAnswer}
      answers={answers}
    />
  );
};

const NumericHistorgramPersonal = ({ taskId }) => {
  const {
    documents: answers,
    loading,
    error,
  } = useFirestore("personalTaskAnswers", { taskId });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return <NumericHistogramWithDataCommon answers={answers} />;
};

const NumericHistogramWithDataCommon = ({
  rightAnswer,
  teamAnswer,
  answers,
}) => {
  const points = answers.map((answer) => answer.answer);

  const markers = {};

  if (rightAnswer !== undefined && rightAnswer !== "") {
    markers["Correct"] = {
      value: Number(rightAnswer),
      offset: 0.1,
      color: "green",
      position: "end",
    };
  }
  if (teamAnswer !== undefined && teamAnswer !== "") {
    markers["Your team"] = {
      value: Number(teamAnswer),
      color: "red",
    };
  }

  return <NumericHistogram points={points} markers={markers} />;
};

export default NumericHistogramWithData;
