import React from "react";
import { useMemo } from "react";
import {
  fetchAll,
  updateBulkDocs,
  useFirestore,
} from "../../hooks/useFirestore";
import { calcPoints } from "../../utils/scoring";

export const PointsAward = ({ task, teamAnswer, teamId }) => {
  const award = calcPoints(task, teamAnswer, teamId);
  return (
    <div className={!award ? "text-red-500" : "text-green-500"}> {award} </div>
  );
};
export const useTeamScores = () => {
  const { documents: teams } = useFirestore("teams", {}, { score: -1 });
  const { documents: userProfiles } = useFirestore("userProfiles");

  const profiles = useMemo(() => {
    const r = {};
    (userProfiles || []).forEach(({ id, displayName }) => {
      const comps = displayName.split(" ");
      comps.pop();
      r[id] = comps.join(" ");
    });
    return r;
  }, [userProfiles]);

  const data = useMemo(
    () =>
      (teams || [])
        .filter(({ isAdmin }) => !isAdmin)
        .map(({ id, name, score, members }) => ({
          id,
          name,
          score,
          members: members.map((x) => profiles[x]),
        })),
    [teams, profiles],
  );

  return data;
};

export const TeamScores = () => {
  const teams = useTeamScores();
  return (
    <table className="w-full border-collapse bg-white shadow-md rounded-lg overflow-hidden">
      <thead>
        <tr className="bg-gray-100">
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Team
          </th>
          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
            Score
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {teams.map((team) => (
          <tr key={team.id}>
            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {team.name}
              <div className="text-xs text-gray-400">
                {team.members.join(", ")}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
              {team.score?.toFixed(2)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// NOTE: we use 'whiplash' as a short-hand adjective
const calcWhiplashWinners = async (tasks, teamAnswers) => {
  const r = {};
  const whiplashTasks = tasks.filter(
    ({ type }) => type === "task_write_in_choice",
  );
  for (const task of whiplashTasks) {
    const answers = teamAnswers.filter(({ taskId }) => taskId === task.id);
    const answerCounts = answers.reduce((acc, { answer }) => {
      acc[answer] = (acc[answer] || 0) + 1;
      return acc;
    }, {});
    const mostCommonAnswer =
      answers.length === 0
        ? null
        : Object.keys(answerCounts).reduce((a, b) =>
            answerCounts[a] > answerCounts[b] ? a : b,
          );
    const winnerTeamId = answers.find(
      (answer) => answer.answer === mostCommonAnswer,
    )?.answerTeamId;
    if (mostCommonAnswer && winnerTeamId) {
      r[task.id] = {
        rightAnswerTeamId: winnerTeamId,
        rightAnswer: mostCommonAnswer,
      };
    } else {
      console.warn(`Failed to find the winner for task: ${task.id}`);
    }
  }

  return r;
};

// run this after a round (group of tasks) is completed
// will make sure every task within the group calculates
export const calcAndApplyNewScores = async (groupId) => {
  if (!groupId) {
    throw new Error("groupId is required to update scores");
  }

  // fetch data deps
  const [teams, teamAnswers, personalAnswers, tasks] = await Promise.all([
    fetchAll("teams"),
    fetchAll("teamTaskAnswers", { groupId }),
    fetchAll("personalTaskAnswers", { groupId }),
    fetchAll("tasks", { groupId }),
  ]);

  // calculate rightAnswer for voting tasks
  const perTaskWinners = await calcWhiplashWinners(tasks, teamAnswers);
  let tasksUpdatePromise = Promise.resolve();

  if (Object.keys(perTaskWinners).length > 0) {
    // update locally
    for (const taskId in perTaskWinners) {
      const { rightAnswer, rightAnswerTeamId } = perTaskWinners[taskId];
      tasks.forEach(({ id }, i) => {
        if (id === taskId) {
          tasks[i] = { ...tasks[i], rightAnswer, rightAnswerTeamId };
        }
      });
    }
    // persist the calculated winner answers
    tasksUpdatePromise = updateBulkDocs(
      "tasks",
      Object.entries(perTaskWinners).map(
        ([taskId, { rightAnswer, rightAnswerTeamId }]) => ({
          id: taskId,
          docObject: {
            rightAnswer,
            rightAnswerTeamId,
          },
        }),
      ),
    );
  }

  // calculate per answer score awards and update team scores
  const { teamScores, answerAwards, teamAwards } =
    await calcNewScoresForAllTeams(
      groupId,
      teams,
      teamAnswers,
      personalAnswers,
      tasks,
    );

  // update data
  await Promise.all([
    tasksUpdatePromise,
    updateBulkDocs(
      "teams",
      Object.entries(teamScores).map(([teamId, newScore]) => ({
        id: teamId,
        docObject: {
          score: newScore,
          [`scores.${groupId}`]: teamAwards[teamId],
        },
      })),
    ),
    updateBulkDocs(
      "teamTaskAnswers",
      Object.entries(answerAwards).map(([answerId, scoreAward]) => ({
        id: answerId,
        docObject: {
          scoreAward,
        },
      })),
    ),
  ]);
};

const calcNewScoresForAllTeams = async (
  groupId,
  teams,
  teamAnswers,
  personalAnswers,
  tasks,
) => {
  const tasksById = {};
  tasks.forEach((task) => {
    tasksById[task.id] = task;
  });

  const teamsById = {};
  teams.forEach((team) => {
    teamsById[team.id] = team;
  });

  const teamAwards = {};
  const teamScores = {};
  const answerAwards = {};
  teamAnswers.forEach(({ id, taskId, answer, teamId }) => {
    teamAwards[teamId] = teamAwards[teamId] || 0;
    const task = tasksById[taskId];
    if (task) {
      const delta = calcPoints(task, answer, teamId);
      answerAwards[id] = delta;
      teamAwards[teamId] += delta;
    }
  });
  personalAnswers.forEach(({ taskId, teamId }) => {
    teamAwards[teamId] = teamAwards[teamId] || 0;
    const task = tasksById[taskId];
    if (task) {
      const delta = Number(task.personalAnswerPoints) || 0;
      teamAwards[teamId] += delta;
    }
  });
  teams.forEach(({ id, scores }) => {
    teamScores[id] = 0;
    const newScores = {
      ...scores,
      [groupId]: teamAwards[id],
    };
    for (const grp in newScores) {
      teamScores[id] = (teamScores[id] || 0) + (newScores[grp] || 0);
    }
  });
  return { teamAwards, answerAwards, teamScores };
};
