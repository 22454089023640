import React from "react";
import { useFirestore } from "../../hooks/useFirestore";

const TeamAnswerPill = ({ teamAnswerId }) => {
  const { documents: answers } = useFirestore("teamTaskAnswers", {
    id: teamAnswerId,
  });

  if (!teamAnswerId || !answers.length) return null;
  const answer = answers[0];

  return (
    <div className="bg-blue-100 rounded-full px-3 py-1 mt-1 w-max">
      {answer.answer}
    </div>
  );
};

export default TeamAnswerPill;
